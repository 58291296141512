import { CircleHelp, Minus } from 'lucide-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import logoImg from '@/assets/images/logo-black.svg';
import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { useBoundStore } from '@/store/store';

import { Image } from '../image/Image';
import { ResetConversation } from '../resetConversation/ResetConversation';

export const TopBar = memo(function TopBar() {
  const { t } = useTranslation();
  const { themeConfig } = useChatConfig();
  const [close, setUnread] = useBoundStore(state => [
    state.close,
    state.setUnread,
  ]);

  /**
   * Close and clear unread messages.
   */
  const handleClose = () => {
    setUnread(0);
    close();
  };

  return (
    <div className='sticky top-0 flex h-14 flex-row items-center justify-between bg-primary px-4'>
      <div className='flex items-center gap-3'>
        <Image className='h-5' src={themeConfig?.logo ?? logoImg} />
      </div>
      <div className='flex gap-4'>
        <a
          href={t('urls.infoPdf')}
          target='_blank'
          className='flex items-center justify-center text-primary-fg transition-transform hover:scale-110 active:scale-100'
          rel='noreferrer'
        >
          <CircleHelp className='size-6' strokeWidth={1.5} />
        </a>
        <ResetConversation />
        <button
          type='button'
          className='flex items-center justify-center text-primary-fg transition-transform hover:scale-110 active:scale-100'
          onClick={handleClose}
        >
          <Minus className='size-6' />
        </button>
      </div>
    </div>
  );
});
