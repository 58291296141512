/**
 * Enum for error codes. These codes are used to identify specific errors
 * and are used for localization on FE.
 */
export enum ErrorCodes {
  // General exceptions
  INTERNAL_SERVER_ERROR = 'internalServerError',

  // Service/entity exceptions
  ENTITY_NOT_FOUND = 'entityNotFound',
  INVALID_DATA = 'invalidData',
  INVALID_ARGUMENT = 'invalidArgument',

  // Auth exceptions
  AUTH_MISSING_TOKEN = 'authMissingToken',
  AUTH_INVALID_TOKEN = 'authInvalidToken',
  AUTH_INVALID_CHAT_USER = 'authInvalidChatUser',
  AUTH_INSUFFICIENT_PERMISSIONS = 'authInsufficientPermissions',

  // Usage limits
  USAGE_LIMITS_EXCEEDED = 'usageLimitsExceeded',

  // Streaming exceptions
  INVALID_TOOL_CALL_CHUNK = 'invalidToolCallChunk',

  // tRPC exceptions
  TRPC_ERROR = 'trpcError',
}
