import { useBoundStore } from '@/store/store';

export function useUser() {
  const userStore = useBoundStore(state => ({
    user: state.user,
    login: state.login,
    logout: state.logout,
    accessToken: state.accessToken,
    setAccessToken: state.setAccessToken,
    setUser: state.setUser,
  }));

  const isLoggedIn = !!userStore.user;

  return { ...userStore, isLoggedIn };
}
