import { useMutation } from '@tanstack/react-query';
import { memo, useCallback, useEffect, useState, useRef } from 'react';

import avatarImg from '@/assets/images/avatar.jpg';
import { useBoundStore } from '@/store/store';

import { Bubble } from './components/bubble/Bubble';
import { Chat } from './components/chat/Chat';
import { ChatWindow } from './components/chat/ChatWindow';
import { Route } from './components/route/Route';
import { Welcome } from './components/welcome/Welcome';
import { useChatConfig } from './contexts/ChatConfigProvider';
import { useUser } from './hooks/useUser';
import { aiCoreApi } from './services/aiCoreClient';

export const Widget = memo(function Widget() {
  const { isLoggedIn } = useUser();
  const [verified, setVerified] = useState(!isLoggedIn);
  const { chatId } = useChatConfig();
  const { open, setUnread } = useBoundStore(state => ({
    open: state.open,
    setUnread: state.setUnread,
  }));

  const handleOpen = useCallback(() => {
    open();
    setUnread(0);
  }, [open, setUnread]);

  const { mutateAsync: verify } = useMutation({
    mutationFn: async (chatId: string) => {
      try {
        const result = await aiCoreApi.auth.verify(
          { chatId },
          {
            retry: 0,
          },
        );

        return result.valid;
      } finally {
        setVerified(true);
      }
    },
  });

  // Use useRef to track if verification was already attempted
  const verificationAttempted = useRef(false);

  // We want to verify the chat user on mount
  useEffect(() => {
    if (!isLoggedIn || verificationAttempted.current) {
      return;
    }

    verificationAttempted.current = true;
    verify(chatId);
  }, []);

  if (!verified) {
    return null;
  }

  return (
    <>
      <ChatWindow>
        <Route name='welcome'>
          <Welcome />
        </Route>
        <Route name='chat'>
          <Chat />
        </Route>
      </ChatWindow>

      <Bubble src={avatarImg} onClick={handleOpen} />
    </>
  );
});
